import { useEffect } from 'react'
import {
  Navigate,
  Outlet, createBrowserRouter, useLocation,
  useNavigate,
} from 'react-router-dom'
import Header from '../components/Header'
import ConfirmScheduledAppointments from '../views/dsiFit/ConfirmScheduledAppointments'
import InvitationExpired from '../views/dsiFit/InvitationExpired'
import InvitationPatientForm from '../views/dsiFit/InvitationPatientForm'
import NoDatesAvailable from '../views/dsiFit/NoDatesAvailable'
import ScheduleDSIAppointment from '../views/dsiFit/ScheduleDSIAppointment'
import ScheduleFITAppointment from '../views/dsiFit/ScheduleFITAppointment'
import SeeYouSoonView from '../views/dsiFit/SeeYouSoon'
import TechnicalDifficulties from '../views/dsiFit/TechnicalDifficulties'
import ValidateInvitation from '../views/dsiFit/ValidateInvitation'
import NotFound from '../views/NotFound'
import ClinicInfo from '../views/selfScheduling/ClinicInfo'
import { SUPPORTED_LANGUAGES } from './constants'

const LayoutComponent = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const includeLanguageSelector = pathname.includes('/dsi-fit') || pathname.includes('/_i/')

  useEffect(() => {
    const pathSegments = pathname.split('/').filter(Boolean) // Split and remove empty segments
    const firstLang = pathSegments[0]
    const secondLang = pathSegments[1]

    if (Object.keys(SUPPORTED_LANGUAGES).includes(firstLang) && firstLang === secondLang) {
      // If the first and second segments are both languages and match, remove the duplicate
      const newPath = `/${pathSegments.slice(1).join('/')}` // Create new path without the first language
      navigate(newPath, { replace: true }) // Redirect to the new path
    }
  }, [pathname, navigate])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <>
      <Header includeLanguageSelector={includeLanguageSelector} />
      <Outlet />
    </>
  )
}

const NotFoundRoot = () => (
  <>
    <Header />
    <NotFound />
  </>
)

const router = createBrowserRouter([
  {
    path: '/:lang?/*',
    element: <LayoutComponent />,
    errorElement: <NotFoundRoot />,
    children: [
      // Redirect rule for duplicate languages
      {
        path: ':lang/:lang/*',
        element: <Navigate
          replace
          to=".."
        />,
      },
      // {
      //   path: 'invitation-info',
      //   element: <InvitationTable />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: '',
      //   element: <Form />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: 'contact-form',
      //   element: <ContactForm />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: 'find-clinic',
      //   element: <ClinicFinder />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: 'select-appointment',
      //   element: <AppointmentSelector />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: 'details-appointment',
      //   element: <AppointmentDetails />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: 'confirm-appointment',
      //   element: <AppointmentConfirmation />,
      //   errorElement: <NotFound />,
      // },
      {
        path: 'clinic-info',
        element: <ClinicInfo />,
        errorElement: <NotFound />,
      },
      // {
      //   path: 'reschedule',
      //   element: <IdentifyPatient />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: 'scheduled-appointment-information',
      //   element: <ScheduledAppointmentInformation />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: 'appointment-cancelled',
      //   element: <AppointmentCancelled />,
      //   errorElement: <NotFound />,
      // },
      // {
      //   path: 'confirm-reschedule',
      //   element: <RescheduleConfirmation />,
      //   errorElement: <NotFound />,
      // },
      {
        path: '_i/:invitationId',
        element: <ValidateInvitation />,
        errorElement: <NotFound />,
      },
      {
        path: 'dsi-fit/*',
        element: <Outlet />,
        errorElement: <NotFound />,
        children: [
          {
            path: 'technical-difficulties',
            element: <TechnicalDifficulties />,
            errorElement: <NotFound />,
          },
          {
            path: 'no-dates-available',
            element: <NoDatesAvailable />,
            errorElement: <NotFound />,
          },
          {
            path: 'invitation-expired',
            element: <InvitationExpired />,
            errorElement: <NotFound />,
          },
          {
            path: 'invitation-form',
            element: <InvitationPatientForm />,
            errorElement: <NotFound />,
          },
          {
            path: 'dsi-appointment',
            element: <ScheduleDSIAppointment />,
            errorElement: <NotFound />,
          },
          {
            path: 'fit-appointment',
            element: <ScheduleFITAppointment />,
            errorElement: <NotFound />,
          },
          {
            path: 'confirm-appointments',
            element: <ConfirmScheduledAppointments />,
            errorElement: <NotFound />,
          },
          {
            path: 'scheduled',
            element: <SeeYouSoonView />,
            errorElement: <NotFound />,
          },
        ],
      },
      // Catch-all route for undefined paths
      {
        path: '*',
        element: <Navigate
          replace
          to="/not-found"
        />,
      },
    ],
  },
  {
    path: '/not-found',
    element: <NotFoundRoot />,
  },
])

export default router
