import React from 'react'
import styled from '@emotion/styled'
import {
  Box, Typography, Modal, TextField,
} from '@mui/material'
import NextButton from './NextButton'

interface PasswordModalProps {
  open: boolean
  onClose: () => void
  onPasswordChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const PasswordModal: React.FC<PasswordModalProps> = ({ open, onClose, onPasswordChange }) => (
  <StyledModal
    aria-describedby="password-modal-description"
    aria-labelledby="password-modal-title"
    open={open}
  >
    <Box sx={{ ...style, width: 400 }}>
      <Typography
        sx={{
          fontSize: '20px',
          marginBottom: '15px',
        }}
      >
        Enter admin password
      </Typography>
      <TextField onChange={onPasswordChange} />
      <NextButton
        onClick={onClose}
        secondary
        text="Log In"
      />
    </Box>
  </StyledModal>
)

const StyledModal = styled(Modal)`
  display: grid;
`

const style = {
  position: 'absolute' as 'absolute',
  display: 'grid',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
}

export default PasswordModal
