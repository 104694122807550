import styled from '@emotion/styled'
import {
  Box, CircularProgress,
  Typography,
} from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import axios from 'axios'
import dayjs from 'dayjs'
import {
  useState,
} from 'react'
import { useForm } from 'react-hook-form'
import { MAX_MOBILE_DIMENSIONS, SS_ENDPOINT } from '../../../app/constants'
import { IServiceCenterInfo } from '../../../app/types'
import FormInputDate from '../../../components/FormInputDate'
import NextButton from '../../../components/NextButton'
import PasswordModal from '../../../components/PasswordModal'

const columns = [
  { field: 'service_center_id', headerName: 'id', width: 100 },
  { field: 'name', headerName: 'Name', width: 350 },
  { field: 'state', headerName: 'State', width: 50 },
  { field: 'resource_name', headerName: 'Resource Name', width: 200 },
  {
    field: 'slots',
    headerName: 'Slots',
    width: 500,
    renderCell: (params: any) => {
      const slots = params.value
      return (
        <div style={{
          maxHeight: '300px', overflowY: 'auto', padding: '10px', lineHeight: '1.5',
        }}
        >
          {Object.keys(slots).map((key) => (
            <div
              key={key}
              style={{ marginBottom: '15px' }}
            >
              <strong style={{ marginRight: '8px' }}>
                {key}
                :
              </strong>
              <span>
                {Array.isArray(slots[key]) ? slots[key].join(', ') : slots[key]}
              </span>
            </div>
          ))}
        </div>
      )
    },
  },
]

const ClinicInfo = () => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState<Array<IServiceCenterInfo>>([])
  const [signedIn, setSignedIn] = useState(true)
  const [password, setPassword] = useState('')
  const [authorized, setAuthorized] = useState(false)
  const {
    control, handleSubmit, formState,
  } = useForm()
  const { errors } = formState

  const handleClose = () => {
    setSignedIn(false)
    getData(dayjs().format('MM/DD/YYYY'))
  }

  const handleSearch = () => (value: any) => {
    const date = dayjs(value.date).format('MM/DD/YYYY')
    setLoading(true)
    getData(date)
  }

  async function getData(date: string) {
    try {
      const response = await axios.get(
        `${SS_ENDPOINT}/api/get-service-centers-info?date=${date}`,
        { headers: { Authorization: password } },
      )
      setData(response.data)
      setAuthorized(true)
    } catch (error: any) {
      if (error.response.status === 401) {
        setAuthorized(false)
      }
    } finally {
      setLoading(false)
    }
  }

  const passwordInput = (e: any) => {
    setPassword(e.target.value)
  }

  if (signedIn) {
    return (
      <PasswordModal
        onClose={handleClose}
        onPasswordChange={passwordInput}
        open={signedIn}
      />
    )
  }
  if (isLoading) {
    return (
      <div style={{
        width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '20px',
      }}
      >
        <CircularProgress size={40} />
      </div>
    )
  }
  if (authorized) {
    return (
      <>
        <FormBox
          component="form"
          onSubmit={handleSubmit(handleSearch())}
        >
          <FormInputDate
            control={control}
            disablePast
            errors={errors}
            name="date"
            placeholder="Search availability by date"
          />

          <NextButton
            isLoading={isLoading}
            secondary
            text="Search"
          />
        </FormBox>
        <StyledBox
          columns={columns}
          rowHeight={200}
          rows={data}
        />
      </>
    )
  }
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Typography
        sx={{
          fontSize: '20px',
          marginBottom: '15px',
        }}
      >
        Unauthorized
      </Typography>
    </Box>
  )
}

const FormBox = styled(Box)`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`

const StyledBox = styled(DataGrid)`
  width: 80%;
  height: 85%;
  position: absolute;
  margin-left: 10%;
  @media (max-width: ${MAX_MOBILE_DIMENSIONS}px) {
    width: 100%;
    margin-left: 0%;
    height: 90%;
  }
`

export default ClinicInfo
